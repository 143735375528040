import Axios from "axios";
import api from "./api";

const instance = Axios.create({
    // baseURL: api.api,
    baseURL: process.env.VUE_APP_BASE_URL,
    timeout: 100000
});

instance.interceptors.request.use(config => {
    config.headers["token"] = sessionStorage.getItem("token") ? sessionStorage.getItem("token"): 'miaolun'
    return config;
},error => {
    return Promise.reject(error);
})

export default instance;
