<template>
  <div
    style="
      width: 100%;
      padding: 30px;
      box-sizing: border-box;
      background-color: rgba(33, 34, 68, 1);
    "
  >
    <van-row>
      <van-col span="24">
        <van-swipe
          class="my-swipe"
          :autoplay="3000"
          indicator-color="white"
          :show-indicators="false"
        >
          <van-swipe-item v-for="(item, index) in bannerList" :key="index">
            <img
              :src="item.image"
              alt=""
              style="width: 100%; height: 100%; border-radius: 20px"
            />
          </van-swipe-item>
        </van-swipe>
      </van-col>
    </van-row>
    <van-row style="margin-top: 20px">
      <van-col span="24" style="font-size: 1.5rem; font-weight: 500">
        {{ $t("xinyidai") }}
      </van-col>
      <van-col span="24" style="margin-top: 10px">
        <div
          style="
            display: flex;
            justify-content: flex-start;
            align-items: center;
          "
        >
          <img
            :src="require('@/assets/image/list1.png')"
            alt=""
            style="width: 24px; height: 24px"
          />
          <span style="margin-left: 20px; box-sizing: border-box">{{
            $t("wuxuzhuanyi")
          }}</span>
        </div>
      </van-col>
      <van-col span="24" style="margin-top: 10px">
        <div
          style="
            display: flex;
            justify-content: flex-start;
            align-items: center;
          "
        >
          <img
            :src="require('@/assets/image/list2.png')"
            alt=""
            style="width: 24px; height: 24px"
          />
          <span style="margin-left: 20px; box-sizing: border-box">{{
            $t("shouruwending")
          }}</span>
        </div>
      </van-col>
      <van-col span="24" style="margin-top: 10px">
        <div
          style="
            display: flex;
            justify-content: flex-start;
            align-items: center;
          "
        >
          <img
            :src="require('@/assets/image/list3.png')"
            alt=""
            style="width: 24px; height: 24px"
          />
          <span style="margin-left: 20px; box-sizing: border-box">{{
            $t("mianfeituichu")
          }}</span>
        </div>
      </van-col>
      <van-col
        span="24"
        style="margin-top: 20px; color: rgba(112, 128, 179, 1)"
      >
        {{ $t("zhiliyudazao") }} <span style="color: #ffffff">DEX,IMO</span>and
        <span style="color: #ffffff">DAO</span>
      </van-col>
    </van-row>
  </div>
  <div
    style="
      width: 100%;
      padding: 30px;
      box-sizing: border-box;
      border-radius: 20px;
      background-color: #212244;
      margin-top: 20px;
    "
  >
    <van-row>
      <van-col span="24" style="font-size: 1.5rem">
        {{ $t("xiangmutese") }}
      </van-col>
      <van-col span="24" style="margin-top: 20px">
        <van-row>
          <van-col span="5">
            <img
              :src="require('@/assets/image/row1.png')"
              alt=""
              style="width: 51px; height: 51px"
            />
          </van-col>
          <van-col span="19">
            <van-row align="center">
              <van-col span="24" style="font-size: 16px">
                {{ $t("anquanyoukekao") }}
              </van-col>
              <van-col
                span="24"
                style="color: #7080b3; margin-top: 10px; box-sizing: border-box"
              >
                {{ $t("wuxuzhuanbi") }}
              </van-col>
            </van-row>
          </van-col>
        </van-row>
      </van-col>
      <van-col span="24" style="margin-top: 20px">
        <van-row>
          <van-col span="5">
            <img
              :src="require('@/assets/image/row2.png')"
              alt=""
              style="width: 51px; height: 51px"
            />
          </van-col>
          <van-col span="19">
            <van-row align="center">
              <van-col span="24" style="font-size: 16px">
                {{ $t("zhuanyewendingxing") }}
              </van-col>
              <van-col
                span="24"
                style="color: #7080b3; margin-top: 10px; box-sizing: border-box"
              >
                {{ $t("zhuanyetuandui") }}
              </van-col>
            </van-row>
          </van-col>
        </van-row>
      </van-col>
      <van-col span="24" style="margin-top: 20px">
        <van-row>
          <van-col span="5">
            <img
              :src="require('@/assets/image/row3.png')"
              alt=""
              style="width: 51px; height: 51px"
            />
          </van-col>
          <van-col span="19">
            <van-row align="center">
              <van-col span="24" style="font-size: 16px">
                {{ $t("jinrumenkandi") }}
              </van-col>
              <van-col
                span="24"
                style="color: #7080b3; margin-top: 10px; box-sizing: border-box"
              >
                {{ $t("fenxiangjiedianwakuang") }}
              </van-col>
            </van-row>
          </van-col>
        </van-row>
      </van-col>
    </van-row>
  </div>
  <div
    style="
      width: 100%;
      padding: 30px;
      box-sizing: border-box;
      border-radius: 20px;
      background-color: #212244;
      margin-top: 20px;
    "
  >
    <van-col span="24" style="font-size: 1.5rem">
      {{ $t("fenjishouru") }}
    </van-col>
    <div>
      <div class="defi-statement-title van-hairline--bottom">{{ $t("DeFi Wallet Balance Mining/Profit Statement") }}</div>
      <van-row justify="center" class="defi-statement-head">
        <van-col span="5">{{ $t("Level") }}</van-col><van-col span="13">{{ $t("Investment Amount") }}</van-col><van-col span="6">{{ $t("Daily Return") }}</van-col>
      </van-row>
      <van-row class="defi-statement-list">
        <van-col span="5">1</van-col><van-col span="13">1000 - 4999 USDT</van-col><van-col span="6">1.00%</van-col>
        <van-col span="5">2</van-col><van-col span="13">5000 - 9999 USDT</van-col><van-col span="6">1.40%</van-col>
        <van-col span="5">3</van-col><van-col span="13">10000 - 29999 USDT</van-col><van-col span="6">1.80%</van-col>
        <van-col span="5">4</van-col><van-col span="13">30000 - 49999 USDT</van-col><van-col span="6">2.60%</van-col>
        <van-col span="5">5</van-col><van-col span="13">50000 - 79999 USDT</van-col><van-col span="6">3.80%</van-col>
        <van-col span="5">6</van-col><van-col span="13">80000 - 149999 USDT</van-col><van-col span="6">4.80%</van-col>
        <van-col span="5">7</van-col><van-col span="13">150000 - 299999 USDT</van-col><van-col span="6">5.80%</van-col>
        <van-col span="5">8</van-col><van-col span="13">300000 - 499999 USDT</van-col><van-col span="6">6.80%</van-col>
        <van-col span="5">9</van-col><van-col span="13">500000 USDT</van-col><van-col span="6">9.80%</van-col>
      </van-row>
    </div>
    <div>
      <div class="defi-statement-title van-hairline--bottom">{{ $t("DeFi Stake Amount Mining/Profit Statement") }}</div>
      <van-row justify="center" class="defi-statement-head">
        <van-col span="5">{{ $t("Level") }}</van-col><van-col span="13">{{ $t("Investment Amount") }}</van-col><van-col span="6">{{ $t("Daily Return") }}</van-col>
      </van-row>
      <van-row class="defi-statement-list">
        <van-col span="5">1</van-col><van-col span="13">1000 - 4999 USDT</van-col><van-col span="6">2.00%</van-col>
        <van-col span="5">2</van-col><van-col span="13">5000 - 9999 USDT</van-col><van-col span="6">2.80%</van-col>
        <van-col span="5">3</van-col><van-col span="13">10000 - 29999 USDT</van-col><van-col span="6">3.80%</van-col>
        <van-col span="5">4</van-col><van-col span="13">30000 - 49999 USDT</van-col><van-col span="6">4.80%</van-col>
        <van-col span="5">5</van-col><van-col span="13">50000 - 79999 USDT</van-col><van-col span="6">5.80%</van-col>
        <van-col span="5">6</van-col><van-col span="13">80000 - 149999 USDT</van-col><van-col span="6">6.80%</van-col>
        <van-col span="5">7</van-col><van-col span="13">150000 - 299999 USDT</van-col><van-col span="6">7.80%</van-col>
        <van-col span="5">8</van-col><van-col span="13">300000 - 499999 USDT</van-col><van-col span="6">8.80%</van-col>
        <van-col span="5">9</van-col><van-col span="13">500000 USDT</van-col><van-col span="6">10.80%</van-col>
      </van-row>
    </div>
  </div>
  <div
    style="
      width: 100%;
      padding: 30px;
      box-sizing: border-box;
      border-radius: 20px;
      background-color: #212244;
      margin-top: 20px;
    "
  >
    <van-row>
      <van-col span="24" style="font-size: 1.5rem">
        {{ $t("hezuo") }}
      </van-col>
      <van-col span="24" style="margin-top: 20px; color: #7080b3">
        <van-row gutter="10" style="margin-top: 10px">
          <van-col span="8">
            <div
              style="
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding: 10px 20px;
                box-sizing: border-box;
                background-color: rgba(44, 44, 83, 1);
                border-radius: 10px;
              "
            >
              <img
                :src="require('@/assets/image/biance.png')"
                alt=""
                style="width: 20px; height: 20px"
              />
              <span style="margin-left: 10px">Binance</span>
            </div>
          </van-col>
          <van-col span="8">
            <div
              style="
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding: 10px 20px;
                box-sizing: border-box;
                background-color: rgba(44, 44, 83, 1);
                border-radius: 10px;
              "
            >
              <img
                :src="require('@/assets/image/ftx.png')"
                alt=""
                style="width: 20px; height: 20px"
              />
              <span style="margin-left: 10px">FTX</span>
            </div>
          </van-col>
          <van-col span="8">
            <div
              style="
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding: 10px 20px;
                box-sizing: border-box;
                background-color: rgba(44, 44, 83, 1);
                border-radius: 10px;
              "
            >
              <img
                :src="require('@/assets/image/kucoin.png')"
                alt=""
                style="width: 20px; height: 20px"
              />
              <span style="margin-left: 10px">kuCoin</span>
            </div>
          </van-col>
        </van-row>
        <van-row gutter="10" style="margin-top: 10px">
          <van-col span="8">
            <div
              style="
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding: 10px 20px;
                box-sizing: border-box;
                background-color: rgba(44, 44, 83, 1);
                border-radius: 10px;
              "
            >
              <img
                :src="require('@/assets/image/kraken.png')"
                alt=""
                style="width: 20px; height: 20px"
              />
              <span style="margin-left: 10px">KraKen</span>
            </div>
          </van-col>
          <van-col span="8">
            <div
              style="
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding: 10px 20px;
                box-sizing: border-box;
                background-color: rgba(44, 44, 83, 1);
                border-radius: 10px;
              "
            >
              <img
                :src="require('@/assets/image/gate.io.png')"
                alt=""
                style="width: 20px; height: 20px"
              />
              <span style="margin-left: 10px">Gate.io</span>
            </div>
          </van-col>
          <van-col span="8">
            <div
              style="
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding: 10px 20px;
                box-sizing: border-box;
                background-color: rgba(44, 44, 83, 1);
                border-radius: 10px;
              "
            >
              <img
                :src="require('@/assets/image/uopbit.png')"
                alt=""
                style="width: 20px; height: 20px"
              />
              <span style="margin-left: 10px">Upbit</span>
            </div>
          </van-col>
        </van-row>
        <van-row gutter="10" style="margin-top: 10px">
          <van-col span="8">
            <div
              style="
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding: 10px 20px;
                box-sizing: border-box;
                background-color: rgba(44, 44, 83, 1);
                border-radius: 10px;
              "
            >
              <img
                :src="require('@/assets/image/okex.png')"
                alt=""
                style="width: 20px; height: 20px"
              />
              <span style="margin-left: 10px">OkEx</span>
            </div>
          </van-col>
          <van-col span="8">
            <div
              style="
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding: 10px 20px;
                box-sizing: border-box;
                background-color: rgba(44, 44, 83, 1);
                border-radius: 10px;
              "
            >
              <img
                :src="require('@/assets/image/bitfinex.png')"
                alt=""
                style="width: 20px; height: 20px"
              />
              <span style="margin-left: 10px">Bitfinex</span>
            </div>
          </van-col>
          <van-col span="8">
            <div
              style="
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding: 10px 20px;
                box-sizing: border-box;
                background-color: rgba(44, 44, 83, 1);
                border-radius: 10px;
              "
            >
              <img
                :src="require('@/assets/image/wazirx.png')"
                alt=""
                style="width: 20px; height: 20px"
              />
              <span style="margin-left: 10px">Wazirx</span>
            </div>
          </van-col>
        </van-row>
        <van-row gutter="10" style="margin-top: 10px">
          <van-col span="8">
            <div
              style="
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding: 10px 20px;
                box-sizing: border-box;
                background-color: rgba(44, 44, 83, 1);
                border-radius: 10px;
              "
            >
              <img
                :src="require('@/assets/image/list1.png')"
                alt=""
                style="width: 20px; height: 20px"
              />
              <span style="margin-left: 10px">huobi global</span>
            </div>
          </van-col>
          <van-col span="8">
            <div
              style="
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding: 10px 20px;
                box-sizing: border-box;
                background-color: rgba(44, 44, 83, 1);
                border-radius: 10px;
              "
            >
              <img
                :src="require('@/assets/image/crypto.com.png')"
                alt=""
                style="width: 20px; height: 20px"
              />
              <span style="margin-left: 10px">crypto.com</span>
            </div>
          </van-col>
          <van-col span="8">
            <div
              style="
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding: 10px 20px;
                box-sizing: border-box;
                background-color: rgba(44, 44, 83, 1);
                border-radius: 10px;
              "
            >
              <img
                :src="require('@/assets/image/coinbase.png')"
                alt=""
                style="width: 20px; height: 20px"
              />
              <span style="margin-left: 10px">coinBase</span>
            </div>
          </van-col>
        </van-row>
      </van-col>
    </van-row>
  </div>
  <div
    style="
      width: 100%;
      margin-top: 20px;
      text-align: center;
      font-size: 0.875rem;
    "
  >
    © 2022 All rights reserved
  </div>
  <div style="height: 90px"></div>
</template>

<script>
export default {
  name: "service",
  data() {
    return {
      baseURL: process.env.VUE_APP_BASE_URL,
      bannerList: [],
      levelList: [
        {
          level_name: "primary",
          min_balance: "30",
          min_income: "1.5",
        },
        {
          level_name: "intermediate",
          min_balance: "60day",
          min_income: "2",
        },
        {
          level_name: "senior",
          min_balance: "90day",
          min_income: "3",
        },
      ],
      bannerList: [
        {
          image: require("../../public/photo.png"),
        },
        {
          image: require("../../public/photo.png"),
        },
      ],
    };
  },
  mounted() {
    // this.getBanner();
    //this.getLevel()
  },
  methods: {
    getBanner() {
      this.$request.post("index/getBanner", "").then((res) => {
        if (res.data.code === 200) {
          this.bannerList = res.data.data;
        }
      });
    },
    getLevel() {
      this.$request.post("index/getLevel", "").then((res) => {
        if (res.data.code === 200) {
          this.levelList = res.data.data;
        }
      });
    },
  },
};
</script>

<style scoped>
.defi-statement-title{
  margin-top: 28px;
  padding-bottom: 10px; 
  margin-bottom: 10px;
  color: rgb(255, 153, 0);
  font-size: 18px;
  font-weight: 600;
}
.defi-statement-head {
  margin-bottom: 10px; 
  font-size: 16px;
  font-weight: 700;
  color: rgb(112, 128, 179);
}
.defi-statement-list{
  line-height: 24px;
  font-size: 14px; 
  color: rgb(112, 128, 179)
}
</style>
